import HttpRequest from "./HttpRequest";
import Helpers from "@/utils/Helpers";

class AlarmApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestGetAlarmList(filters) {
    let query_string = Helpers.generateQueryString(filters);
    const request_url = "/care/alarms" + query_string;
    const data = await this.get(request_url);
    return data;
  }

  async requestGetAlarmSummary(filters) {
    let query_string = Helpers.generateQueryString(filters);
    const request_url = "/care/alarms/summary" + query_string;
    const data = await this.get(request_url);
    return data;
  }

  async requestGetAlarmById(alarm_id) {
    const request_url = "/care/alarms/" + alarm_id;
    const data = await this.get(request_url);
    return data;
  }

  async requestCreateAckEventByAlarmId(alarm_id) {
    const request_url = "/care/alarms/" + alarm_id + "/ack";
    const data = await this.update(request_url);
    return data;
  }
}

export default AlarmApiProvider;
