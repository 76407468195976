import AlarmApiProvider from "@/services/AlarmApiProvider";

const alarm_api_provider = new AlarmApiProvider();
alarm_api_provider.setAuthorizationHeadersFromLocal();

export default {
  state: {
    selected_alarm: null,
    alarm_list: [],
    total_page: 0,
    current_page: 0,
    is_next_page: false,
    alarm_summary: null,
    alarm_summary_extend: null,
    filter_page: null,
    filter_site: null,
    filter_search: null,
    filter_show_close: null,
    filter_show_extend_summary: null,
    loading_alarm_list: true,
    loading_alarm_summary: true,
  },
  getters: {
    request_filters(state) {
      let filters = {};
      if (state.filter_page) {
        const key = Object.keys(state.filter_page)[0];
        filters[key] = state.filter_page[key];
      }

      if (state.filter_site) {
        const key = Object.keys(state.filter_site)[0];
        filters[key] = state.filter_site[key];
      }

      if (state.filter_search) {
        const key = Object.keys(state.filter_search)[0];
        filters[key] = state.filter_search[key];
      }

      if (state.filter_show_close) {
        const key = Object.keys(state.filter_show_close)[0];
        filters[key] = state.filter_show_close[key];
      }
      return filters;
    },
    alarm_summary_filters(state) {
      let filters = {};
      if (state.filter_site) {
        const key = Object.keys(state.filter_site)[0];
        filters[key] = state.filter_site[key];
      }

      if (state.filter_show_extend_summary) {
        const key = Object.keys(state.filter_show_extend_summary)[0];
        filters[key] = state.filter_show_extend_summary[key];
      }

      return filters;
    },
  },
  actions: {
    async requestGetAlarmList(
      { commit, getters },
      called_by_scrolling = false
    ) {
      try {
        if (!called_by_scrolling) {
          commit("SET_LOADING_ALARM_LIST", true);
        }
        const response = await alarm_api_provider.requestGetAlarmList(
          getters.request_filters
        );
        if (!called_by_scrolling) {
          commit("SET_LOADING_ALARM_LIST", false);
        }
        commit("SET_ALARM_PAGINATION", response.pagination);
        if (response.pagination.page == 0) {
          commit("SET_ALARMS", response.data);
        } else {
          commit("APPEND_ALARMS", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async requestGetAlarmSummary({ commit, getters }) {
      try {
        if (
          getters.alarm_summary_filters &&
          !getters.alarm_summary_filters.extend
        ) {
          commit("SET_ALARM_SUMMARY", null);
        }

        const response = await alarm_api_provider.requestGetAlarmSummary(
          getters.alarm_summary_filters
        );
        if (
          getters.alarm_summary_filters &&
          getters.alarm_summary_filters.extend
        ) {
          commit("SET_ALARM_SUMMARY_EXTEND", response);
        } else {
          commit("SET_ALARM_SUMMARY", response);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async requestGetAlarmById({ commit }, alarm_id) {
      try {
        const response = await alarm_api_provider.requestGetAlarmById(alarm_id);
        commit("SET_SELECTED_ALARM", response);
      } catch (error) {
        console.log(error);
      }
    },
    async requestCreateAckEventByAlarmId({ commit }, alarm_id) {
      try {
        const response =
          await alarm_api_provider.requestCreateAckEventByAlarmId(alarm_id);
        commit("UPDATE_LOCAL_ALARMS", response);
        commit("SET_SELECTED_ALARM", response);
      } catch (error) {
        console.log(error);
      }
    },
    assignAlarmFilterOptionToStore({ commit, state }, filter_option) {
      let filter_params = null;
      switch (filter_option.key) {
        case "page":
          filter_params = { page: state.current_page + 1 };
          commit("SET_FILTER_PAGE", filter_params);
          break;

        case "siteId":
          filter_params = filter_option.value
            ? { siteId: filter_option.value }
            : null;
          commit("SET_FILTER_PAGE", null);
          commit("SET_FILTER_SITE", filter_params);

          break;

        case "showClose":
          filter_params = filter_option.value ? { showClose: "1" } : null;
          commit("RESET_ALARM_STATE");
          commit("SET_FILTER_CLOSE_ALARM", filter_params);
          break;

        case "search":
          filter_params = filter_option.value
            ? { search: filter_option.value }
            : null;
          commit("SET_FILTER_PAGE", null);
          commit("SET_FILTER_SEARCH_TEXT_ALARM", filter_params);
          break;

        case "extend":
          filter_params = filter_option.value ? { extend: "1" } : null;
          commit("SET_FILTER_EXTEND_SUMMARY", filter_params);
          break;

        default:
          break;
      }
    },
  },
  mutations: {
    RESET_ALARM_STATE(state) {
      state.alarm_list = [];
      state.total_page = 0;
      state.current_page = 0;
      state.filter_page = null;
    },
    SET_ALARMS(state, payload) {
      state.alarm_list = payload;
    },
    APPEND_ALARMS(state, payload) {
      state.alarm_list = state.alarm_list.concat(payload);
    },
    SET_ALARM_PAGINATION(state, payload) {
      state.total_page = payload.total;
      state.current_page = payload.page;

      if (payload.page !== payload.total - 1) {
        state.is_next_page = payload.count > payload.limit;
      } else {
        state.is_next_page = false;
      }
    },
    SET_ALARM_SUMMARY(state, payload) {
      state.alarm_summary = payload;
    },
    SET_ALARM_SUMMARY_EXTEND(state, payload) {
      state.alarm_summary_extend = payload;
    },
    SET_SELECTED_ALARM(state, payload) {
      state.selected_alarm = payload;
    },
    SET_FILTER_PAGE(state, payload) {
      state.filter_page = payload;
    },
    SET_FILTER_SITE(state, payload) {
      state.filter_site = payload;
    },
    SET_FILTER_CLOSE_ALARM(state, payload) {
      state.filter_show_close = payload;
    },
    SET_FILTER_SEARCH_TEXT_ALARM(state, payload) {
      state.filter_search = payload;
    },
    SET_FILTER_EXTEND_SUMMARY(state, payload) {
      state.filter_show_extend_summary = payload;
    },
    SET_LOADING_ALARM_LIST(state, payload) {
      state.loading_alarm_list = payload;
    },
    UPDATE_LOCAL_ALARMS(state, payload) {
      const found_index = state.alarm_list.findIndex(
        (alarm) => alarm._id === payload._id
      );
      if (found_index !== -1) {
        state.alarm_list[found_index].status = "ack";
      }
    },
  },
};
